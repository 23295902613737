body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  width: 100%;
  display: block;
}

.header {
  height: 100vh;
  padding: 0 !important;
  background-image: url("https://i.imgur.com/Bh8SyOc.png");
  background-size: 100%;
}
.header-container {
  
  max-width: 600px;
  margin-top: 150px;

}
.header-title {
color: #DF0000;
margin-bottom: 30px;
font-size: 3.8rem;
}
.header-text {
color: #fff;
margin-bottom: 30px;
font-size: 1.3rem;
}
.header-btn {
  border-radius: 50px;
  background-color: #fff;
  padding:10px 45px;
  color: #DF0000;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
}
.button-scootx {
  border-radius: 50px;
  background-color: #DF0000;
  padding:10px 45px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  width: 200px;
}
/* .slider-image {
  height: 590px;
}

.slider-title {
  color: #de1f2a;
    font-size: 5rem;
    font-weight: 700;

}

.slider-parag {
  font-size: 2rem;
  font-weight: 700;
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #de1f2a;
}
 .carousel-control-next:hover, 
 .carousel-control-prev:hover  {
background-color: rgba(255, 255, 255, 0.466);
} */

.title {
  color: #343338;
  font-size: 2.7rem;
  font-weight: 400;
  padding-left: 30px;
}
.parag {
  color: #343338;
  font-size: 1.6rem;
  font-weight: 300;
}
.text-justify {
  text-align: justify !important;
}

.card-about {
  max-width: 400px;
  margin: auto;
}

.card-about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.card-vihecule {
  background-color: #fff;
  max-width: 100%;
  height: 100%;
  padding: 30px 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card-vihecule:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card-vihecule-descreption{
display: flex;
flex-direction: column;
justify-content: center;
}
.card-vihecule-image{
width: 200px;
}

.title-card-vihecule {
  color: #DF0000;
  font-size: 1.8rem;
}

.formulaire {
  width: 100%;
  max-width: 700px;
  height: 100%;
 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border-radius: 20px;
}
.formulaire > .form {
  width: 100%;
  padding: 40px 30px;
}
.formulaire:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.form-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /* justify-content: space-evenly; */
}
.form-div:last-child {
  margin-bottom: 0 !important;
}
.form-div >input {
  width: 100%;
  border: 1px solid #DF0000;
  border-radius: 5px;
  height: 40px;
}
.form-div label{
  width: 200px;
}

.container-form-btn a {
  border: 1px solid #DF0000!important;
  color: #DF0000 !important;
  border-radius: 5px;
  border: none;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

.container-form-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container-form-btn >p {
margin: 0 10px;
}

.btn-switch-container {
  width: 100%;
}
.btn-switch-container button{
  width: 50%;
}

.footer{
  padding: 20px 50px;
  background-color: #343338;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.social-footer >i {
  font-size: 30px;
  color: #fff;
  margin: 0 30px;
}